#footer {  
  color: white;

  .social-icons a svg {
    fill: #ffffff;  
  }

  .social-icons a svg:hover {
    fill: gray;
  }

  .fb-link {
    margin-left: 20px;
    margin-right: 20px;  
  }

  #copyright-footer {
    margin-top: 20px;
    font-size: 0.4em;
    color: white;
  }
}





