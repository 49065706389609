:root {
  --border-size: 0.125rem;
  --duration: 250ms;
  --ease: cubic-bezier(0.215, 0.61, 0.355, 1);
  --font-family: monospace;
  --color-primary: white;
  --color-secondary: black;
  --color-tertiary: dodgerblue;
  --shadow: rgba(0, 0, 0, 0.1);
  --space: 1rem;
}

* {
  box-sizing: border-box;
}

.homepage-btn-container {
  margin-top: 3vh;
  display: flex;
  width: 100%;
  box-shadow: var(--shadow) 4px 4px;
  
  .home-btn {
    flex-grow: 1;
    cursor: pointer;
    position: relative;
    padding:
      calc(var(--space) / 1.125)
      var(--space)
      var(--space);
    border: var(--border-size) solid black;
    color: var(--color-secondary);
    background-color: var(--color-primary);
    font-size: 1.5rem;
    font-family: var(--font-family);    
    text-shadow: var(--shadow) 2px 2px;
    transition: flex-grow var(--duration) var(--ease);
  }
  
}

.homepage-btn-container button + button {
  border-left: var(--border-size) solid black;
  margin-left: calc(var(--border-size) * -1);
}

.homepage-btn-container button:hover,
.homepage-btn-container button:focus {
  flex-grow: 2;
  color: white;
  outline: none;
  text-shadow: none;
  background-color: var(--color-secondary);
}

.homepage-btn-container button:focus {
  outline: var(--border-size) dashed var(--color-primary);
  outline-offset: calc(var(--border-size) * -3);
}

.homepage-btn-container:hover button:focus:not(:hover) {
  flex-grow: 1;
  color: var(--color-secondary);
  background-color: var(--color-primary);
  outline-color: var(--color-tertiary);
}

.homepage-btn-container button:active {
  transform: translateY(var(--border-size));
}